<template>
    <div class="home">
        <v-container>
            <v-card-title>
                Welcome
            </v-card-title>
            <v-card-text>
                Check out the Structures menu on the top!
            </v-card-text>
        </v-container>
    </div>
</template>

<script>
export default {
    name: 'Home',
    components: {}
}
</script>
<style scoped>
.padding {
    padding-top: 2em;
}
</style>
