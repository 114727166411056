
























import {Component, Vue} from 'vue-property-decorator';
import {AuthBackendAPI} from "@/logic/authentication/AuthBackendAPI";

@Component({
    components: {}
})
export default class App extends Vue {
    created() {
        this.$store.dispatch('updateUser')
    }

    isAdmin(): boolean {
        return AuthBackendAPI.isAdmin(this.$store.state.currentUserState);
    }
}
