import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home',
        meta: {
            title: 'DomiNATION Ark Stats'
        },
        component: Home
    },
    {
        path: '/dinos',
        name: 'DinoList',
        meta: {
            title: 'Dinos - DomiNATION Ark Stats'
        },
        props: true,
        component: () => import(/* webpackChunkName: "dinolist" */ '../views/DinoList.vue')
    },
    {
        path: '/structures',
        name: 'Structures',
        meta: {
            title: 'Structures - DomiNATION Ark Stats'
        },
        component: () => import(/* webpackChunkName: "structures" */ '../views/Structures.vue')
    },
    {
        path: '/structures/:id',
        name: 'StructureById',
        meta: {
            title: 'Structure - DomiNATION Ark Stats'
        },
        props: true,
        component: () => import(/* webpackChunkName: "structurebyid" */ '../views/StructureById.vue')
    },
    {
        path: '/tribe/:id',
        name: 'TribeById',
        meta: {
            title: 'Tribe - DomiNATION Ark Stats'
        },
        props: true,
        component: () => import(/* webpackChunkName: "tribebyid" */ '../views/TribeById.vue')
    },
    {
        path: '/player/:id',
        name: 'PlayerById',
        meta: {
            title: 'Player - DomiNATION Ark Stats'
        },
        props: true,
        component: () => import(/* webpackChunkName: "playerbyid" */ '../views/PlayerById.vue')
    },
    {
        path: '/dino/:id',
        name: 'DinoById',
        meta: {
            title: 'Dino - DomiNATION Ark Stats'
        },
        props: true,
        component: () => import(/* webpackChunkName: "dinobyid" */ '../views/DinoById.vue')
    },
    {
        path: '/tribelogs',
        name: 'TribeLogs',
        meta: {
            title: 'Tribe Logs - DomiNATION Ark Stats'
        },
        props: true,
        component: () => import(/* webpackChunkName: "tribelogs" */ '../views/TribeLogs.vue')
    },
    {
        path: '/breedingStats',
        name: 'BreedingStats',
        meta: {
            title: 'Dino Breeding Stats - DomiNATION Ark Stats'
        },
        props: true,
        component: () => import(/* webpackChunkName: "breedingstats" */ '../views/BreedingStats.vue')
    },
    {
        path: '/itemStats',
        name: 'ItemStats',
        meta: {
            title: 'Item Stats - DomiNATION Ark Stats'
        },
        props: true,
        component: () => import(/* webpackChunkName: "itemStats" */ '../views/ItemStats.vue')
    },
    {
        path: '/search/item',
        name: 'ItemSearch',
        meta: {
            title: 'Item Search - DomiNATION Ark Stats'
        },
        props: true,
        component: () => import(/* webpackChunkName: "itemSearch" */ '../views/ItemSearch.vue')
    },
    {
        path: '/search/tribe',
        name: 'TribeSearch',
        meta: {
            title: 'Tribe Search - DomiNATION Ark Stats'
        },
        props: true,
        component: () => import(/* webpackChunkName: "tribeSearch" */ '../views/TribeSearch.vue')
    },
    {
        path: '/servermessages',
        name: 'ServerMessages',
        meta: {
            title: 'Server Messages - DomiNATION Ark Stats'
        },
        props: true,
        component: () => import(/* webpackChunkName: "serverMessages" */ '../views/ServerMessages.vue')
    },
    {
        path: '/purge',
        name: 'PurgeFlags',
        meta: {
            title: 'Purge Flags'
        },
        props: true,
        component: () => import(/* webpackChunkName: "purgeFlags" */ '../views/PurgeFlags.vue')
    },
    {
        path: '/logs',
        name: 'Logs',
        meta: {
            title: 'Search Logs'
        },
        props: true,
        component: () => import(/* webpackChunkName: "logs" */ '../views/Logs.vue')
    },
    {
        path: '/rcon',
        name: 'RCON',
        meta: {
            title: 'Send RCON Message'
        },
        props: true,
        component: () => import(/* webpackChunkName: "rcon" */ '../views/Rcon.vue')
    },
    {
        path: '/admin',
        name: 'AdminHome',
        meta: {
            title: 'Admin Home'
        },
        props: true,
        component: () => import(/* webpackChunkName: "adminlinks" */ '../views/AdminLinks.vue')
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode!.removeChild(el));

    // Skip rendering meta tags if there are none.
    if(!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map((tagDef: any) => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
        });

        // We use this to track which meta tags we create, so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
    })
        // Add the meta tags to the document head.
        .forEach((tag: any) => document.head.appendChild(tag));

    next();
});

export default router
